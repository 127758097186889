"use client"

import { RoleButtons } from "@/components/ui/button/RoleButtons"
import useScreenSize from "@/hooks/misc/useScreenSize"
import { ProfileOutput } from "@/interfaces/profile/ProfileOutput"
import { useHomeProfileStore } from "@/stores/ui/useHomeProfileStore"
import { getAssetsPath } from "@/utils/getAssetsPathUtils"
import { Box, Fade } from "@mui/material"
import Image from "next/image"

interface HeroSectionProps {
  profiles?: ProfileOutput[]
}

export default function HeroSection({ profiles }: Readonly<HeroSectionProps>) {
  const { isApplicant } = useHomeProfileStore()

  const { isDownSm } = useScreenSize()

  return (
    <>
      {profiles && profiles.length > 0 && (
        <RoleButtons
          profiles={profiles}
          sx={{
            margin: 1,
            justifyContent: "center",
            color: "blueMsGov.main",
            backgroundColor: "white",
            borderColor: "white",
          }}
        />
      )}
      <Box
        component="section"
        sx={{
          width: "100%",
          textAlign: "center",
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            height: { xs: "475px" },
            padding: 2,
            my: 0,
            background:
              "linear-gradient(0deg, rgba(196, 217, 238, 0),  rgba(0, 0, 0, 0.18) 99%)",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: {
                xs: "3%",
                md: "5%",
              },
              left: {
                md: `calc(50% - ${!isDownSm ? 250 : 150})`,
              },
              zIndex: 1,
            }}
          >
            <Image
              alt="logo do ms qualifica"
              src={getAssetsPath() + "/img/logos-governo-funtrab-branco.svg"}
              width={0}
              height={0}
              priority={true}
              style={{
                objectFit: "contain",
                width: !isDownSm ? 500 : 300,
                height: "auto",
              }}
            />
          </Box>

          <div style={{ backgroundColor: "yellow" }}>
            <Fade in={isApplicant()} timeout={300} appear={false} exit>
              <Image
                alt="banner da página para candidatos"
                src={`${getAssetsPath()}/img/novo-home-trabalhador-1.png`}
                unoptimized
                fill
                priority
                style={{
                  objectFit: "cover",
                  objectPosition: "center",
                  borderRadius: "inherit",
                  zIndex: -1,
                }}
              />
            </Fade>
          </div>
        </Box>
      </Box>
    </>
  )
}
