import { useModalContext } from "@/contexts/ModalContext"
import { PersonBase } from "@/interfaces/person/PersonBase"
import { useUserProfileStore } from "@/stores/ui/useUserProfileStore"
import { Button, Stack, SxProps, Typography } from "@mui/material"

interface UserInformationProps {
  person: PersonBase | null
  sx?: SxProps
}

export function UserInformation({ person, sx }: UserInformationProps) {
  const { clicked } = useUserProfileStore()
  const { openModal } = useModalContext()

  return (
    <Stack direction="row" alignItems="right" textAlign="end" sx={{ ...sx }}>
      <Stack direction="column" alignItems="flex-end" spacing={-0.3}>
        <Typography sx={{ margin: 0, padding: 0 }} fontWeight="bold">
          {person?.name}
        </Typography>
        <Typography
          sx={{ margin: 0, padding: 0 }}
          variant="caption"
          color={clicked ? "text.secondary" : "white"}
        >
          {person?.email}
        </Typography>
        <Button
          variant="text"
          color="inherit"
          sx={{
            fontSize: "0.75rem",
            padding: 0,
            margin: 0,
            minWidth: "auto",
            textTransform: "uppercase",
          }}
          onClick={() => openModal("logoutModal")}
        >
          Sair
        </Button>
      </Stack>
    </Stack>
  )
}
