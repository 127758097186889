import useScreenSize from "@/hooks/misc/useScreenSize"
import { useUserProfileStore } from "@/stores/ui/useUserProfileStore"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import { Avatar, IconButton } from "@mui/material"

export interface ProfilePictureProps {
  sx?: object
  photoUrl?: string
}

export function ProfilePicture({ sx, photoUrl }: Readonly<ProfilePictureProps>) {
  const { clicked, setClicked } = useUserProfileStore()
  const { isUpMd } = useScreenSize()

  return (
    <IconButton
      onClick={() => {
        if (!isUpMd) setClicked(!clicked)
      }}
      aria-label="informações do usuário autenticado"
      sx={{ color: "white", ...sx }}
    >
      {photoUrl ? (
        <Avatar alt="Foto do usuário" src={photoUrl} sx={{ width: 56, height: 56 }} />
      ) : (
        <AccountCircleIcon
          sx={{ color: "inherit", fontSize: { xs: "3.5rem", sm: "3.5rem" } }}
        />
      )}
    </IconButton>
  )
}
