import { useProfiles } from "@/hooks/misc/useProfiles"
import { Box } from "@mui/material"

import { ProfilePicture } from "./ProfilePicture"
import { UserInformation } from "./UserInformation"
import { UserInformationWrapper } from "./UserInformationWrapper"

export function UserProfile() {
  const { person } = useProfiles()
  return (
    <Box>
      <UserInformationWrapper>
        <UserInformation
          person={person}
          sx={{ display: { xs: "none", md: "block" }, color: "white" }}
        />
        <ProfilePicture photoUrl={person?.photoUrl} />
      </UserInformationWrapper>
    </Box>
  )
}
